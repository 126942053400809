header {
  margin-top: 72px;
}

.header {
  &__caption {
    position: absolute;
  }
  &__text {
    &__show {
      opacity:1;
      background-color: $base-purple;
      transition:opacity 0.5s linear;
      z-index: 10;
    }
    &__hidden {
      opacity: 0;
    }
  }
  &__hidden {
    opacity: 0;
    z-index: -50;
  }
  &__show {
    opacity:1;
    transition:opacity 0.5s linear;
    z-index: -50;
  }
  &__img {
    @media screen and (max-width: $breakpoint-medium) {
      display: none;
    }
    width: 50%;
    position: absolute;
    top: -135px;
    right: -87px;
    height: 76%;
    width: 50%;
    max-height: 480px;
    border-radius: 64px;
    overflow: hidden;
    transform: rotateY(0deg) rotate(-27deg);
    &__caption {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 280px;
      height: 106px;
      transform: rotate(27deg);
      top: 45%;
      left: -34px;
      p {
        width: 85%;
        margin-left: 16px;
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #FFFFFF
      }
    }
    img {
      height: 112%;
      width: 150%;
      object-fit: cover;
      border-radius: 29px;
      transform: rotateY(0deg) rotate(27deg);
      position: absolute;
      left: -352px;
      top: 68px;
    }
    &__main {
      z-index: -100;
    }
  }
  &__svg {
    width: 100%;
    height: 100%;
  }
  &__text {
    width: $container;
    margin: auto;
    display: flex;
    flex-direction: column;
    &__column {
      @media screen and (max-width: $breakpoint-medium) {
        width: 100%;
        align-items: center;
      }
      width: 40%;
      .button--primary {
        width: 275px;
      }
    }
  }
}


.header2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: $container;
  margin: auto;
  margin-top: 140px;
  position: relative;
  @media screen and (max-width: $breakpoint-medium) {
    margin-top: 0;
    height: 80vh;
  }
  @media screen and (max-width: $breakpoint-small-550) {
    flex-direction: column;
  }
}

.header2--responsive {
  display: none;
  @media screen and (max-width: $breakpoint-medium) {
    display: block;
    position: absolute;
    z-index: -100;
    width: 100vw;
    height: 80vh;
    object-fit: cover;
    opacity: .25;
  }
}

.header2__caption {
  width: 40%;
  max-width: 465px;
  min-width: 360px;
  min-height: 140px;
  background: #FFFFFF;
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;

  position: absolute;
  bottom: 5%;
  right: 0;

  @media screen and (max-width: $breakpoint-medium) {
    width: 40%;
    min-height: 240px;
    min-width: unset;
    background: #FFFFFF;
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.14);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    position: absolute;
    bottom: 5%;
    left: 70%;
  }

  @media screen and (max-width: $breakpoint-small-550) {
    width: 100%;
    min-height: 140px;
    max-height: unset;
    padding: 1rem;
    box-sizing: border-box;
    position: unset;
    flex-direction: row;
  }
}

.header2__caption--text {
  text-align: left;
  margin-left: 24px;
  h6 {
    margin-top: 0;
  }
  p {
    margin: 4px 0;
  }
  @media screen and (max-width: $breakpoint-medium) {
    margin: auto;
    text-align: center;
  }
}

.caption__logo {
  width: 20%;
  height: auto;
  border-radius: 0;
  margin-left: 16px;

  @media screen and (max-width: $breakpoint-medium) {
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 0;
    width: 40%;
  }

  @media screen and (max-width: $breakpoint-small-550) {
    width: 25%;
  }
}

.header2__text {
  width: 55%;
  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
  }
  @media screen and (max-width: $breakpoint-small-550) {
    margin-top: 48px;
  }
}

.header2__text__column {
  width: 65%;
  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
  }
}

.header2__img {
  width: 65%;
  @media screen and (max-width: $breakpoint-small-550) {
    width: 100%;
  }
}

.imgContainer {
  width: 90%;
  height: 400px;
  object-fit: cover;
  border-radius: 0px 100px 0px 100px;
  @media screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}
