// Primary Color
$base-red: #F24C27;
$base-purple: #526AF2;

// Secondary Color
$base-mint-green: #ACE2D0;
$base-blue: #203276;

// Feedback Color
$base-green: #0EDC97;
$base-yellow: #FBC506;

// GreyScale
$base-black: #030140;
$base-grey: #C0BFCF;
$base-dark-grey: #A4A6AD;
$base-light-grey: #EFEFEF;
$base-white: #FFFFFF;

// Contrast extended Color
$red-lighten-75: #F5795D;
$red-lighten-50: #F8A593;
$red-lighten-25: #FCD2C9;
$red-darken-25: #E72A00;
$purple-lighten-75: #7D8FF5;
$purple-lighten-50: #A8B4F8;
$purple-lighten-25: #D4DAFC;
$purple-lighten-5: #EBEEFD;
$purple-darken-25: #404FA1;
$mint-lighten-10: #D6EFE9;

// Brand Color Array
$brand-color: $base-red, $base-purple, $base-mint-green, $base-blue;

@mixin color-loop($child) {
  @for $i from 1 through 4 {
    &:nth-child(#{$i}) #{$child} {
      background-color: nth($brand-color, $i);
    }
  }
}

:export {
  baseRed: $base-red;
  basePurple: $base-purple;
  baseMintGreen: $base-mint-green;
  baseYellow: $base-yellow;
  baseGreen: $base-green;
  baseBlack: $base-black;
  baseGrey: $base-grey;
  baseLightGrey: $base-light-grey;
  baseWhite: $base-white;
  redLighten50: $red-lighten-50;
  redLighten25: $red-lighten-25;
  purpleLighten50: $purple-lighten-50;
  purpleLighten25: $purple-lighten-25;
}
