.temoignage {
  margin-bottom: 72px;
  &__button {
    margin-top: 32px;
    min-width: 300px;
  }
  .container {
    margin: auto;
  }
  &__row {
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: $breakpoint-medium) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 48px;
    }
    column-gap: 64px;
  }
  &__item {
    &__button {
      color: $base-purple;
      float: right;
      margin-bottom: 0;
      .icon {
        margin-right: 8px;
      }
    }
    &__cover {
      width: 100%;
      border-radius: 15px;
      height: 180px;
      object-fit: cover;
    }
     &__logo {
       width: 25%;
       margin-right: 16px;
      object-fit: contain;
      @media screen and (max-width: $breakpoint-small-550) {
        max-height: 64px;
      }
     }
    h4 {
      margin: 16px 0;
    }
    &__quote {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: $breakpoint-small-550) {
        p {
          margin-top: 0;
        }
      }
    }
  }
}
