.solution {
  margin: 64px auto;
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 8px;
    margin: {
      top: 64px;
    }
    @media screen and (max-width: $breakpoint-medium-tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $breakpoint-small) {
      grid-template-columns: repeat(1, 1fr);
    }
    &__cell {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      img {
        width: 40%;
        margin: auto;
      }
      p {
        font-weight: 500;
        min-height: 50px;
      }
    }
  }
}
