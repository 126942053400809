.home {
  &__title {
    width: 60%;
    margin: auto;
    text-align: center;
    h6 {
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 130%;
      text-transform: uppercase;
      margin: {
        bottom: 0;
      }
    }
    h2 {
      margin: {
        top: 16px;
        bottom: 24px;
      }
    }
    &--purple {
      h6 {
        color: $base-purple;
      }
      .separator {
        height: 5px;
        background-color: $base-purple;
        border-radius: 5px;
        width: 150px;
        margin: auto;
      }
    }
    &--red {
      h6 {
         color: $base-red;
      }
      .separator {
        height: 5px;
        background-color: $base-red;
        border-radius: 5px;
        width: 150px;
        margin: auto;
      }
    }

    @media screen and (max-width: $breakpoint-medium) {
      width: 90%;
    }
  }
}
