.player {
  position: fixed;
  z-index: 10000;
  background: #000000ab;
  color: white;
  width: 100%;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__close {
    width: 50%;
    color: $base-white;
    text-align: right;
  }
  &__responsive {
    width: 50%;
    @media screen and (max-width: $breakpoint-medium) {
      width: 80%;
    }
  }
}

.videoContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
