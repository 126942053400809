.navbar {
  position: fixed;
  top: 16px;
  left: 0;
  right: 0;
  z-index: 1000;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: $container;
  height: 75px;
  margin: 16px auto;

  background: #FFFFFF;
  box-shadow: 0px 13px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  padding: 0px 24px;

  @media screen and (max-width: $breakpoint-medium-tablet) {
    width: 100%;
    top: 0;
    margin: 0;
  }

  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    flex-direction: column;
    height: unset;
    max-height: unset;
    border-radius: 0;
    &__icon {
      transition: .3s;
    }

    &__icon:hover {
      color: $base-purple;
      font-size: 24px;
    }
  }

  &--mobile {
    display: none;
    @media screen and (max-width: $breakpoint-medium) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__menu--mobile {
    display: none;
    @media screen and (max-width: $breakpoint-medium) {
      display: flex;
    }
  }

  &__section {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: $breakpoint-medium) {
      min-width: 35%;
    }
    @media screen and (max-width: $breakpoint-medium) {
      display: none;
      &__button {
        margin-bottom: 0;
      }
    }
    &--mobile {
      display: none;
      @media screen and (max-width: $breakpoint-medium) {
        display: flex;
      }
    }
    &__link {
      background: none;
      margin: {
        right: 24px;
        bottom: 0;
      }
      &--border {
        box-sizing: border-box;
        border: {
          bottom: 4px solid $base-white;
        }
        border-radius: 0px;
        &:hover {
          color: $base-red;
          border: {
            bottom: 4px solid $base-red;
          }
        }
      }
      &--classic {
        &:hover {
          color: $base-red;
        }
      }
    }
    .button--primary {
        width: 275px;
        height: 48px;
        font-size: 16px;
        margin: {
          bottom: 0;
        }
        align-self: center;
        @media screen and (max-width: $breakpoint-medium) {
          min-width: 150px;
        }
    }
  }
}

.navbar__section__link__active--border {
  color: $base-red;
  border: {
    bottom: 4px solid $base-red;
  }
}

.backToHomePage {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  height: 75px;
}
