@import "~rfs/scss";
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

button, .button {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  @include font-size(16px);
}

h1, .h1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  @include font-size(48px);
}

h2, .h2 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  @include font-size(36px);
}

h3, .h3 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  @include font-size(30px);
}

h4, .h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  @include font-size(28px);
}

h5, .h5 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  @include font-size(24px);
}

h6, .h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  @include font-size(20px);
}

a, p, span, .body-1, .p {
  font-family: 'Roboto', sans-serif;
  @include font-size(16px);
  line-height: 150%;
}

b {
  font-family: 'Roboto', sans-serif;
  @include font-size(16px);
}

.body-2 {
  font-family: 'Roboto', sans-serif;
  @include font-size(16px);
}

.caption {
  font-family: 'Roboto', sans-serif;
  @include font-size(14px);
}

.subtitle-1 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  @include font-size(20px);
}

.subtitle-2 {
  font-family: 'Raleway', sans-serif;
  @include font-size(16px);
}
