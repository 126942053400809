@import './theme/_normalize.scss';
@import './theme/_reset.scss';
@import './theme/_breakpoints.scss';
@import './theme/_colors.scss';
@import './theme/_typography.scss';
@import './theme/_button.scss';
@import './theme/_layout.scss';
@import './theme/transitions.css';

@import './style/home.scss';
@import './style/navbar.scss';
@import './style/header.scss';
@import './style/social.scss';
@import './style/solution.scss';
@import './style/beneficiaire.scss';
@import './style/processus.scss';
@import './style/temoignage.scss';
@import './style/contact.scss';
@import './style/footer.scss';
@import './style/player.scss';

body {
  overflow-x: hidden;
}

.container {
  width: $container;
}

.logo {
  &--ayomi {
    font-family: Fjalla One;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    display: flex;
    align-items: center;
    margin-right: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    text-transform: uppercase;

    color: #000000;

  }
}
