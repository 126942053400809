.footer {
  background-color: $purple-lighten-5;
  padding: 64px 0;
  margin-top: 24px;

  &__button {
    width: 250px;
  }
  &__column {
    display: flex;
    flex-direction: column;
    a {
      margin: 4px 0;
      transition: .3s;
    }
    a:hover {
      color: $base-purple;
    }
  }
  &__input {
    border-radius: 5px;
    border: 2px solid $base-grey;
    height: 49px;
    width: 100%;
    font-family: Roboto;
    padding-left: 8px;
    margin-bottom: 16px;
    &:focus {
      border-color: $base-purple;
      outline: none;
    }
    &:hover {
      border-color: $base-purple;
    }
  }
  &__grid {
    margin: auto;

    display: grid;
    grid-template-columns: 2fr 1.5fr 1.5fr 1.5fr 2.5fr;
    column-gap: 32px;
    @media screen and (max-width: $breakpoint-medium) {
      grid-template-columns: repeat(1, 1fr);
    }

    .logo--ayomi {
      margin-top: 0;
    }
  }
  &__social {
    display: flex;
    flex-direction: row;
    &__icon {
      width: 48px;
      height: 48px;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 16px;

      background-color: $base-purple;
      border-radius: 100%;
      color: $base-white;
      transition: .3s;
      &:hover {
        background-color: $purple-darken-25;
      }
      &:active {
        background-color: $purple-lighten-75;
      }
    }
  }
  &__title {
    font-weight: 700;
  }
}
