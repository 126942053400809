.input {
  height: 42px;
  width: 100%;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  color: #AEADBE;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-family: roboto;
}

.input .ant-tooltip {
  display: none;
}

.input input {
  border: none;
  height: 40px;
  width: 90%;
  box-sizing: border-box;
  outline: none;
  background: none;
  color: black;
}

.input:focus-within {
  box-shadow: 0px 0px 5px #526AF2;
  border-color: #526AF2;
  outline: none;
}

.label {
  margin-bottom: 8px;
  text-align: left;
}

.hint {
  font-size: 12px;
  margin-top: 4px;
}
