.social {
  margin: {
    top: 104px;
  }
  @media screen and (max-width: $breakpoint-medium) {
    margin-top: 0;
  }
  background-color: $purple-lighten-5;
  &__antishadow {
    margin:20px;
    display:table;
    overflow:hidden;
    @media screen and (max-width: $breakpoint-medium) {
      display: none;
    }
  }
  &__shadow {
    margin-right: 20px;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    box-shadow: 10px 0px 15px rgba(0, 0, 0, 0.05);
    height: 170px;
    width: 24px;
    background: $purple-lighten-5;
    @media screen and (max-width: $breakpoint-medium) {
      display: none;
    }
  }
  &__container {
    display: flex;
    flex-direction: row;
    margin: auto;
    @media screen and (max-width: $breakpoint-medium) {
      flex-direction: column;
    }
  }
  &__text {
    width: 35%;
    max-width: 300px;
    @media screen and (max-width: $breakpoint-medium) {
      width: 100%;
      max-width: unset;
    }
    &__link {
      color: $base-purple;
      transition: color .3s;
      @media screen and (max-width: $breakpoint-medium) {
        margin-bottom: 0;
      }
      &:hover {
        color: $purple-lighten-75;
      }
    }
    &__title {
      margin: {
        bottom: 8px;
      }
    }
  }
  &__slideshow {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;
    @media screen and (max-width: $breakpoint-medium) {
      height: 60px;
    }
  }
  &__slider {
    background-position: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 600%;
    animation: slideshow 40s linear infinite;
    @media screen and (max-width: $breakpoint-small-550) {
      background-size: 100% !important;
    }
  }
}

.social__slider__background {
  height: 170px;
  width: 1000px;
  animation: slide 30s linear infinite;
  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
    height: auto;
    animation: none;
  }
}

@keyframes slideshow {
  0%    { left: 0%; }
  100%  { left: -300%; }
}

@keyframes slide {
  0%{
    transform: translate3d(60%, 0, 0);
  }
  100%{
    transform: translate3d(-4771px, 0, 0); /* The image width */
  }
}
