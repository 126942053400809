button {
  background: none;
  outline: none;
  margin: 0;
}

a {
  color: black;
  text-decoration: none;
}
