.contact {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
  }
  margin: 48px auto;
  justify-content: space-between;
  &__background {
    position: absolute;
    width: 100%;
  }
  &__section {
    position: relative;
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    overflow: hidden;
    @media screen and (max-width: $breakpoint-medium) {
      width: 100%;
      margin-bottom: 48px;
    }
    &__content {
      width: 80%;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 10;
      h4 {
        margin: {
          bottom: 0;
        }
      }
      p {
        margin: 16px 0;
      }
      button {
        margin: {
          bottom: 48px;
        }
      }
    }
    &--blue {
      background-color: $purple-lighten-5;
    }
    &--green {
      background-color: $mint-lighten-10;
    }
  }
}
