button {
  border: none;
  border-radius: 5px;
  min-height: 48px;
  min-width: 48px;
  margin-bottom: 16px;
  outline: none;
  cursor: pointer;
}

.button--primary {
  background-color: $base-red;
  color: white;
  transition: background-color .3s;
  &:hover {
    background-color: $red-darken-25;
  };
  &:active {
    background-color: $red-lighten-75;
  };
  &:focus {
    box-shadow: 0px 0px 5px rgba(242, 76, 39, 0.75);
  }
}

.button--purple {
  background-color: $base-purple;
  color: white;
  transition: background-color .3s;
  &:hover {
    background-color: $purple-darken-25;
  }
  &:active {
    background-color: $purple-lighten-75;
  };
  &:focus {
    box-shadow: 0px 0px 5px rgba(82, 106, 242, 0.75);
  }
}

.button--secondary {
  background-color: $base-light-grey;
  color: $base-black;
  &:hover {
    background-color: #f7f7f7;
  }
}
