.button {
  border: none;
  border-radius: 5px;
  padding: 8px 24px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 4px;
}
.small {
  height: 38px;
  min-width: 88px;
}

.medium {
  height: 48px;
  min-width: 112px;
}

.large {
  height: 72px;
  min-width: 150px;
}

.primary {
  background-color: #F24C27;
  color: white;
}

.primary--disabled{
  background-color: #C0BFCF;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}

.primary--inactive{
  background-color: #C0BFCF;
  color: white;
  box-shadow: none;
}

.primary:hover {
  background-color: #E72A00;
}

.primary--disabled:hover,
.primary--disabled:active,
.primary--disabled:focus {
  background-color: #C0BFCF;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}

.primary--inactive:hover,
.primary--inactive:active,
.primary--inactive:focus {
  background-color: #E72A00;
  color: white;
  box-shadow: none;
}

.primary:active {
  background-color: #F5795D;
}

.primary:focus {
  box-shadow: 0px 0px 5px rgba(242, 76, 39, 0.75);
}


.secondary {
  background-color: #526AF2;
  color: white;
}

.secondary--disabled{
  background-color: #C0BFCF;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}

.secondary--inactive{
  background-color: #C0BFCF;
  color: white;
  box-shadow: none;
}

.secondary:hover {
  background-color: #404FA1;
}

.secondary--disabled:hover,
.secondary--disabled:active,
.secondary--disabled:focus {
  background-color: #C0BFCF;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}

.secondary--inactive:hover,
.secondary--inactive:active,
.secondary--inactive:focus {
  background-color: #404FA1;
  color: white;
  box-shadow: none;
}

.secondary:active {
  background-color: #7D8FF5;
}

.secondary:focus {
  box-shadow: 0px 0px 5px rgba(82, 106, 242, 0.75);
}

.tertiary {
  background: none;
  border: solid 2px #F24C27;
  color: #F24C27;
}

.tertiary--disabled{
  border: solid 2px #C0BFCF;
  color: #C0BFCF;
  cursor: not-allowed;
  box-shadow: none;
}

.tertiary--inactive{
  border: solid 2px #C0BFCF;
  color: #C0BFCF;
  box-shadow: none;
}

.tertiary:hover {
  border: solid 2px #E72A00;
  background-color: rgba(242, 76, 39, 0.09);
}

.tertiary--disabled:hover,
.tertiary--disabled:active,
.tertiary--disabled:focus {
  cursor: not-allowed;
  box-shadow: none;
}

.tertiary--inactive:hover,
.tertiary--inactive:active,
.tertiary--inactive:focus {
  border: solid 2px #E72A00;
  background-color: rgba(242, 76, 39, 0.09);
}

.tertiary:active {
  background-color: rgba(242, 76, 39, 0.09);
}

.tertiary:focus {
  background-color: rgba(242, 76, 39, 0.09);
  box-shadow: 0px 0px 5px rgba(242, 76, 39, 0.75);
}
