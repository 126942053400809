.beneficiaire {
  background-color: $purple-lighten-5;
  padding: {
    top: 24px;
    bottom: 24px;
  }
  &__main {
    margin: auto;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 48px auto;
    @media screen and (max-width: $breakpoint-medium) {
      flex-direction: column;
    }
    &__text {
      width: 50%;
      margin-left: 40px;
      @media screen and (max-width: $breakpoint-medium) {
        width: 100%;
        margin-left: 0;
        margin-top: 16px;
      }
      .text {
        margin: {
          top: 0;
        }
      }
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    @media screen and (max-width: $breakpoint-medium) {
      grid-template-columns: repeat(1, 1fr);
    }
    &__cell {
      &__title {
        color: $base-purple;
        font-weight: 700;
        .icon {
          margin-right: 16px;
        }
      }
    }
  }
  &__slider {
    width: 40%;
    @media screen and (max-width: $breakpoint-medium) {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
}
