.label {
  margin-bottom: 8px;
  text-align: left;
}

.hint {
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
}

.inputSuccess {
  border: 1px solid #49AD40;
}

.inputSuccess:focus-within {
  box-shadow: none;
}

.hintSuccess {
  color: #49AD40;
}

.iconSuccess {
  color: #49AD40;
  margin-right: 16px;
}

.inputError {
  border: 1px solid #F24C27;
}

.inputError:focus-within {
  box-shadow: none;
}

.hintError {
  color: #F24C27;
}

.iconError {
  color: #F24C27;
  margin-right: 16px;
}

.inputDisabled {
  background: #F1F1F4;
  border: 1px solid #C4C4C4;
}

.inputDisabled:focus-within {
  box-shadow: none;
}

.iconContainer {
  width: 38px;
  background: #F1F1F4;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  border-left: 1px solid #C0BFCF;
  border-radius: 0 5px 5px 0px;
}

.container {
  width: 98%;
  margin-right: 2%;
}
