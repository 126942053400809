.processus {
  margin: auto;
  margin-bottom: 128px;
  @media screen and (max-width: $breakpoint-medium) {
    margin-bottom: 48px;
  }
  @media screen and (max-width: $breakpoint-small-550) {
    margin-bottom: 0;
  }
  &__main {
    min-height: 600px;
    position: relative;
    @media screen and (max-width: $breakpoint-medium-tablet) {
      min-height: 900px;
    }
    @media screen and (max-width: $breakpoint-small-550) {
      min-height: 2012px;
    }
  }
  &__background--large {
    position: absolute;
    width: 100%;
    height: 600px;
    margin: 48px auto;
    z-index: -10;
    right: 0;
    left: 0;
    @media screen and (max-width: $breakpoint-medium-tablet) {
      display: none;
    }
  }
  &__background--medium {
    display: none;
    position: absolute;
    z-index: -10;
    right: 0;
    left: 0;
    height: 900px;
    width: 100%;
    object-fit: cover;
    @media screen and (max-width: $breakpoint-medium-tablet) {
      display: block;
    }
    @media screen and (max-width:  $breakpoint-small-550) {
      display: none;
    }
  }
  &__background--small {
    display: none;
    position: absolute;
    margin: 48px auto;
    z-index: -10;
    right: 0;
    left: 0;
    @media screen and (max-width:  $breakpoint-small-550) {
      display: block;
    }
  }
  &__illustration {
    max-height: 100px;
    width: 100%;
    margin: auto;
  }
  &__grid {
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 330px 330px;
    column-gap: 100px;
    justify-content: center;
    @media screen and (max-width: $breakpoint-medium-tablet) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 24px;
      column-gap: 32px;
    }
    @media screen and (max-width: $breakpoint-small-550) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 64px;
      grid-template-rows: unset;
    }
    &__cell {
      @media screen and (max-width: $breakpoint-small-550) {
        display: flex;
        justify-content: center;
      }
    }
  }
  &__inner__cell {
    width: 100%;
    margin: auto;
    max-width: 220px;
    @media screen and (max-width: $breakpoint-medium-tablet) {
      width: 100%;
    }
  }
  &__step {
    background-color: $base-purple;
    color: white;
    font-family: 'Roboto';
    width: 25px;
    height: 25px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width .3s;
    button {
      color: white;
      margin-bottom: 0;
      opacity: 0;
    }
  }
  &__cell {
    &--center {
      align-self: center;
    }
    &--end {
      align-self: end;
      @media screen and (max-width: $breakpoint-medium-tablet) {
        align-self: center;
      }
    }
    &__background {
      display: grid;
      grid-template-columns: 25px 150px;
      justify-content: center;
      align-items: center;
      background-color: $purple-lighten-5;
      border-radius: 5px;
      padding: 8px;
      .icon {
        color: $base-purple;
        margin-right: 16px;
      }
      p {
        margin: 4px 0;
      }
    }
    &__title {
      text-align: center;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 8px;
    }
  }
}

.c3 {
  grid-column: 3;
  grid-row: 2;
  @media screen and (max-width: $breakpoint-medium-tablet) {
    grid-column: auto;
    grid-row: auto;
  }
}

.c2 {
  grid-column: 2;
  grid-row: 2;
  @media screen and (max-width: $breakpoint-medium-tablet) {
    grid-column: auto;
    grid-row: auto;
  }
}

.c1 {
  grid-column: 1;
  grid-row: 2;
  @media screen and (max-width: $breakpoint-medium-tablet) {
    grid-column: auto;
    grid-row: auto;
  }
}
